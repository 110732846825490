@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'Ruda', sans-serif;

}
.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #D97706; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.table th:first-child{
  position: relative;
}

.nsm7Bb-HzV7m-LgbsSe.JGcpL-RbRzK{
  border-radius: 16px !important;
}

button:disabled{
  background: #fcc7a1 !important;
  color: #968475;
}

input[type="time"]::-webkit-calendar-picker-indicator{
  background-color:#FF8C0B;
  }

  .table th:first-child{
    z-index: 0;
  }
